<template>
  <v-card>
    <v-row class="pa-2">
      <v-col
        class="py-0 pl-4 my-5 d-flex justify-center align-center"
        v-for="(palestra, i) in palestras"
        :key="i"
        cols="12"
        md="12"
      >
        <v-card
          width="100%"
          max-width="1200px"
          :to="{ path: 'palestras/' + palestra.id }"
        >
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card
                tile
                flat
                height="220px"
                max-height="220px"
                max-width="280px"
                class="fill-height justify-center align-center d-flex"
              >
                <v-img
                  contain
                  height="220px"
                  max-height="220px"
                  max-width="280px"
                  v-if="palestra.img_destaque"
                  :src="palestra.img_destaque"
                >
                </v-img>
                <v-icon v-else size="60" color="info">
                  mdi-image-multiple-outline
                </v-icon>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="px-3 py-1 d-flex justify-space-between"
              style="flex-direction: column"
            >
              <div class="d-flex align-center justify-center">
                <span
                  class="text-subtitle-1 font-weight-bold text-h5 to-uppercase py-2"
                >
                  {{ palestra.descricao | excerpt(35) }}
                </span>
                <v-spacer></v-spacer>
                <v-btn icon @click.stop.prevent="deletePalestra(palestra.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click.stop.prevent="
                        !palestra.exibir_app
                          ? exibirNoApp(palestra.id)
                          : naoExibirNoApp(palestra.id)
                      "
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        :color="palestra.exibir_app ? '' : 'grey lighten-1'"
                        >{{
                          palestra.exibir_app ? "mdi-eye" : "mdi-eye-off"
                        }}</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{
                    palestra.exibir_app
                      ? "A palestra está sendo exibida no app"
                      : "A palestra não está sendo exibida no app"
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click.stop.prevent="
                        !palestra.liberar_avaliacao
                          ? permitirAvaliacoes(palestra.id)
                          : naoPermitirAvaliacoes(palestra.id)
                      "
                      icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        :color="
                          palestra.liberar_avaliacao ? '' : 'grey lighten-1'
                        "
                        >{{
                          palestra.liberar_avaliacao
                            ? "mdi-comment"
                            : "mdi-comment-off"
                        }}</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{
                    palestra.liberar_avaliacao
                      ? "A palestra está permitindo avaliações"
                      : "A palestra não está permitindo avaliações"
                  }}</span>
                </v-tooltip>

                <div class="d-block">
                  <div class="d-flex align-center justify-center">
                    <span class="text-h5 text-center pr-2">
                      {{ palestra.data | dateFormat("dd") }}
                    </span>
                    <span class="text-subtitle-1 text-center justify-center">
                      {{ formatData(palestra.data, "MMM") }}
                    </span>
                  </div>
                  <div class="d-block">
                    <!-- <span class="text-caption">
                      Início às
                      {{
                        (palestra.data + " " + palestra.hora)
                          | dateFormat("HH:mm")
                      }}
                    </span> -->
                  </div>
                </div>
              </div>
              <br />
              <span class="text-body-2">
                {{ palestra.descricao_detalhada | excerpt(115) }}
              </span>
              <div class="d-flex align-center my-4">
                <span class="text-subtitle-2 mr-2">Palestrante:</span>
                <div>
                  <v-img
                    cover
                    class="palestrante-avatar"
                    v-if="palestra.palestrante_avatar"
                    :src="palestra.palestrante_avatar"
                  ></v-img>
                </div>
                <div class="ml-2 d-block">
                  <div>
                    <span class="text-subtitle-2">
                      {{ palestra.palestrante | excerpt(25) }}
                    </span>
                  </div>

                  <div v-if="palestra.palestrante_ramoatividade">
                    <span class="text-caption">
                      {{ palestra.palestrante_ramoatividade | excerpt(25) }}
                    </span>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column">
                  <v-btn text small>
                    <v-icon left>mdi-plus</v-icon>
                    leia mais
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <DialogDeletePalestra
        v-if="dialogDeletePalestra"
        :dialogDeletePalestra.sync="dialogDeletePalestra"
        :palestra_id="palestra_id"
        @fetch-palestras="getPalestras"
      />
    </v-row>
  </v-card>
</template>

<script>
import { format, parseISO } from "date-fns";
import dateLocalePortugues from "date-fns/locale/pt-BR";
import {
  all as fetchPalestras,
  exibirPalestra,
  naoExibirPalestra,
  permitirAvaliarPalestra,
  naoPermitirAvaliarPalestra,
} from "@/api/admin/palestras.js";
export default {
  props: {
    diaPalestra: {
      type: String,
    },
  },

  data() {
    return {
      loading: true,
      palestras: [],
      palestra: {},
      dialogDeletePalestra: false,
      dialogAvaliacoes: false,
      palestra_id: null,
    };
  },

  components: {
    DialogDeletePalestra: () => import("./components/DialogDeletePalestra.vue"),
  },

  methods: {
    deletePalestra(item) {
      this.dialogDeletePalestra = true;
      this.palestra_id = item;
    },

    goToPalestra(item) {
      this.$router.push({ path: `/palestras/${item.id}` });
    },

    getPalestras() {
      this.loading = true;
      fetchPalestras("?dia=" + this.diaPalestra)
        .then((response) => {
          this.palestras = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    formatData(date, formato) {
      let options = {
        locale: dateLocalePortugues,
      };
      return format(parseISO(date), formato, options);
    },

    exibirNoApp(item) {
      exibirPalestra(item)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("A Palestra será mostrada no app");
            this.getPalestras();
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    naoExibirNoApp(item) {
      naoExibirPalestra(item)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("A Palestra não será mostrada no app");
            this.getPalestras();
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    permitirAvaliacoes(item) {
      permitirAvaliarPalestra(item)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("A Palestra poderá ser avaliada");
            this.getPalestras();
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    naoPermitirAvaliacoes(item) {
      naoPermitirAvaliarPalestra(item)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("A Palestra não poderá ser avaliada");
            this.getPalestras();
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    openAvaliacoes(palestra) {
      console.log(palestra);
      this.dialogAvaliacoes = true;
    },
  },

  mounted() {
    this.getPalestras();
  },
};
</script>

<style lang="scss" scoped>
.to-uppercase {
  text-transform: uppercase;
}
.palestrante-avatar {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 100%;
}
</style>
