import api from "../axios_service.js";

export async function all(filtros) {
  const response = await api.get(`/admin/palestras${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function one(id) {
  const response = await api.get(`/admin/palestras/${id}`);
  return response.data.result;
}

export function putPalestra(id, palestra) {
  return api.post(`/admin/palestras/${id}`, palestra);
}

export function postPalestra(palestra) {
  return api.post(`/admin/palestras`, palestra);
}

export function ordenarPalestras(palestra) {
  return api.put(`/admin/palestras/ordenar`, palestra);
}

export function exibirPalestra(id) {
  return api.put(`/admin/palestras/exibir/${id}`);
}

export function naoExibirPalestra(id) {
  return api.put(`/admin/palestras/nao-exibir/${id}`);
}

export function deletePalestra(id) {
  return api.delete(`/admin/palestras/${id}`);
}

export function permitirAvaliarPalestra(id) {
  return api.put(`/admin/palestras/avaliar/${id}`);
}

export function naoPermitirAvaliarPalestra(id) {
  return api.put(`/admin/palestras/nao-avaliar/${id}`);
}

export async function getAvaliacoesFromPalestra(id) {
  const response = await api.get(`/avaliacoes-palestra/${id}`);
  return response.data.result;
}
